import React, { useCallback, useMemo } from "react";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../../../redux/hooks";
import { isAdmin } from "../../../../../_utils/authUtils";
import { SearchLocal } from "../../../../../_components/Search/SearchLocal";
import ActionNewButton from "app/_components/ActionNewButton";
import { LeadStatus } from "../../../../../../data/schemas";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export function LeadFilesFilter() {
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return {
      setQueryParams: leadFilesUIContext.setQueryParams,
      openNewFileDialog: leadFilesUIContext.openNewFileDialog,
      openNewInvoiceDialog: leadFilesUIContext.openNewInvoiceDialog,
      queryParams: leadFilesUIContext.queryParams,
      readOnly: leadFilesUIContext.readOnly,
      setIsInvoice: leadFilesUIContext.setIsInvoice,
    };
  }, [leadFilesUIContext]);

  const { session, groups, lead } = useAppSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    lead: state.leads.leadForEdit.current,
  }));

  const actionNewButtonClick = useCallback(() => {
    leadFilesUIProps.setIsInvoice(true);
    leadFilesUIProps.openNewFileDialog();
  }, [leadFilesUIProps]);

  return (
    <>
      {lead.status !== LeadStatus.WON && (
        <div className="mb-4 bg-light-info px-4 py-2 font-weight-bold">
          <span className="svg-icon svg-icon-lg svg-icon-info mr-2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
          </span>
          <FormattedMessage id="LEAD.ONLY_WON_INVOICE_WARNING" />
        </div>
      )}
      <div className="form-filtration d-md-flex">
        <div className={"margin-bottom-10-mobile flex-grow-1 w-100"} style={{ maxWidth: "100%" }}>
          <SearchLocal useEntityUIContext={useLeadFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start mx-md-2 mt-2">
          {!leadFilesUIProps.readOnly && (
            <>
              {lead.status === LeadStatus.WON &&
                (isAdmin(groups, session) || session?.userTypes?.includes("USER")) && (
                  <ActionNewButton
                    className="btn-light ml-md-2 flex-grow-1 flex-shrink-0"
                    onClick={actionNewButtonClick}
                    messageId="INVOICE.ACTION.CREATE.NEW"
                    disabled={!lead}
                  />
                )}
              <button
                type="button"
                className="btn btn-primary ml-2 flex-grow-1"
                onClick={() => leadFilesUIProps.openNewFileDialog()}
                disabled={!lead}
              >
                <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
