import axios from "axios";
import { endsWith, isEmpty } from "lodash-es";

import { toAbsoluteUrl } from "_metronic/_helpers";
import { KycFile } from "../modules/kyc-v2/Kyc";
import { getLangShort } from "../modules/kyc-v2/KycUtils";
import { IntlShape } from "react-intl";
import { IFile } from "../../data/schemas";
import { LEAD, USER } from "./dataTypes";
import { extension, lookup } from "mime-types";

const fileIconsBasePath = "/media/svg/icons/Files/";

//----------------------------------------------------------------------------//

export const mapMimeTypeFilename: Record<string, string> = {
  "text/csv": "csv",
  "application/gzip": "zip",
  "application/zip": "zip",
  "application/x-7z-compressed": "zip",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xls",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "ppt",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "doc",
  "application/pdf": "pdf",
  "application/xml": "xml",
  "text/xml": "xml",
  "image/svg+xml": "xml", //"svg",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/bmp": "",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.ms-excel": "xls",
  "application/xhtml+xml": "html",
  "text/html": "html",
  "video/mp4": "mp4",
  "video/mpeg": "MEDIA",
  "video/webm": "MEDIA",
  "video/quicktime": "MEDIA",
  "text/plain": "txt",
};

export const getIconFilePath = (mimeType?: string) => {
  let iconFilename = mimeType ? mapMimeTypeFilename[mimeType] : "file";
  //if no specific svg available, use default file.svg
  if (!iconFilename || iconFilename === "") {
    iconFilename = "file";
  }
  return toAbsoluteUrl(fileIconsBasePath + iconFilename + ".svg");
};

export const isValidFileForPreview = (file: any) => {
  return (
    file?.url &&
    !file?.url?.includes("x-id=PutObject") &&
    file?.mimeType &&
    (file?.friendlyName || file?.name)
  );
};

export function addInformationFields(fileForEdit: any, sessionId: string) {
  const { name, type: mimeType } = fileForEdit.file;

  fileForEdit.fileExtension = getFileExtension(name);
  fileForEdit.mimeType = mimeType;
  fileForEdit.uploadedAt = new Date().toISOString();
  fileForEdit.uploadedByUserId = sessionId;
}
// it also supports extensions like .tar.gz
export const getFileExtension = (filename: string) =>
  (filename.match(/(\.([^.]*?)(\.([^.]*?))?)(?=\?|#|$)/) || [])[1] ?? "";

//---===---//

/* eslint-disable */
const SANITIZE_FILENAME_REGEXP = {
  NO_LEFT_SPACES: /^\s+/g,
  NO_SEQ_DOTS: /\.+/g,
  ILLEGAL: /[\/\?<>\\:\*\|"]/g,
  CONTROL: /[\x00-\x1f\x80-\x9f]/g,
  RESERVED: /^\.+$/,
  WINDOWS_RESERVED: /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i,
  WINDOWS_TRAILING: /[\. ]+$/,
};
/* eslint-enable */

interface SanitizeFilenameOptions {
  replacement?: string;
  maxLength?: number;
  fileExtension?: string;
}

export const sanitizeFilename = (
  input: string,
  { replacement = "", maxLength = 255, fileExtension }: SanitizeFilenameOptions = {}
) => {
  if (input.length === 0) {
    throw new Error("The input needs to be defined");
  }

  const {
    NO_LEFT_SPACES,
    NO_SEQ_DOTS,
    ILLEGAL,
    CONTROL,
    RESERVED,
    WINDOWS_RESERVED,
    WINDOWS_TRAILING,
  } = SANITIZE_FILENAME_REGEXP;

  let sanitized = input
    .split("?")[0]
    .split("#")[0]
    .replace(NO_SEQ_DOTS, ".")
    .replace(NO_LEFT_SPACES, replacement)
    .replace(ILLEGAL, replacement)
    .replace(CONTROL, replacement)
    .replace(RESERVED, replacement)
    .replace(WINDOWS_RESERVED, replacement)
    .replace(WINDOWS_TRAILING, replacement);

  if (sanitized.length <= maxLength) {
    if (fileExtension && !endsWith(sanitized, fileExtension))
      return `${sanitized}.${fileExtension}`;
    return sanitized;
  }

  fileExtension = fileExtension ?? getFileExtension(sanitized);
  const fileExtensionLength = fileExtension.length;

  let endIndex = maxLength - 1;

  if (fileExtensionLength === 0) {
    return sanitized.slice(0, endIndex);
  }

  // the latest index is equals to length -1 and we also need to consider the . char before the extension
  endIndex = maxLength - (fileExtensionLength + 2);

  return `${sanitized.slice(0, endIndex)}.${fileExtension}`;
};

export const mapSpecialCaseExtension: Record<string, string> = {
  ".qt": ".mov",
};

const formatFileExtension = (fileExtension: string) => {
  if (!mapSpecialCaseExtension.hasOwnProperty(fileExtension)) {
    return fileExtension;
  }

  return mapSpecialCaseExtension[fileExtension];
};

const mimeTypeToExtension = (mimeType: string) => {
  const fileExtension = extension(mimeType);
  if (!fileExtension) {
    return "";
  }
  return formatFileExtension("." + extension(mimeType));
};

const formatFileName = (file: any) => {
  const filename = sanitizeFilename(file?.friendlyName);
  const isFilenameWithExtension = lookup(filename);

  if (isFilenameWithExtension) {
    return filename;
  }

  if (file?.fileExtension && !filename.includes(file?.fileExtension)) {
    const existingFileExtension = file?.fileExtension.startsWith(".")
      ? file?.fileExtension
      : "." + file?.fileExtension;

    return filename + formatFileExtension(existingFileExtension);
  }

  const fileExtensionString = mimeTypeToExtension(file?.mimeType) || "";
  const isFileExtensionInFilename = filename.endsWith(fileExtensionString);

  if (!isFileExtensionInFilename) {
    return filename + "." + fileExtensionString;
  }

  return filename;
};

export const downloadFile = (file: any, setProgress?: Function) => {
  if (isValidFileForPreview(file)) {
    axios({
      url: file?.url,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        if (setProgress) {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)); // you can use this to show user percentage of file downloaded
        }
      },
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", formatFileName(file));
      document.body.appendChild(link);
      link.click();
    });
  }
};

export const getFileItemWithRelatedEntity = (fileRowItem: any) => {
  if (fileRowItem?.leadId) {
    return {
      ...fileRowItem,
      relatedEntity: {
        id: fileRowItem.leadId,
        type: LEAD,
      },
    };
  } else if (fileRowItem?.userId) {
    return {
      ...fileRowItem,
      relatedEntity: {
        id: fileRowItem.userId,
        type: USER,
      },
    };
  }
};

export const handleDownloadFile = (e: any, file: KycFile | IFile) => {
  e.preventDefault();
  e.stopPropagation();
  downloadFile(file);
};

export const formatFriendlyNameWithLang = (friendlyName: string, locale: string): string => {
  const langFormatted = getLangShort(locale, true);
  return langFormatted ? friendlyName + " (" + langFormatted + ")" : friendlyName;
};

export const formatFileFriendlyNameWithLang = (file: KycFile): string => {
  if (!file?.lang) {
    return file?.friendlyName;
  }
  return formatFriendlyNameWithLang(file?.friendlyName, file?.lang);
};

export const orderByLang = (files: any[], intl: IntlShape) => {
  if (!files || isEmpty(files) || files.length === 1) {
    return files;
  } else {
    const fs = [...files];
    const indexFirstEntry = fs?.findIndex((f: any) => f?.lang === intl.locale);
    if (indexFirstEntry > -1) {
      const entry0 = { ...fs?.[indexFirstEntry] };
      fs.splice(indexFirstEntry, 1);
      return [entry0, ...fs];
    }
    return files;
  }
};
//----------------------------------------------------------------------------//

export const exportCSV = (CSVname: string, content: any) => {
  const exportedDate = new Date();

  const exportedFilename = CSVname + "_" + exportedDate.toISOString().split("T")[0] + ".csv";

  const csvContent = content.map((e: any) => e.join(",")).join("\n");
  const encoder = new TextEncoder();
  const utf8Data = encoder.encode(csvContent);
  const base64Data = btoa(String.fromCharCode(...new Uint8Array(utf8Data)));

  downloadFile({
    url: `data:text/plain;charset=utf-8;base64,${base64Data}`,
    friendlyName: exportedFilename,
    mimeType: "text/csv",
  });
};
